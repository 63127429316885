<template>
  <div class="vx-row ml-5">
    <vs-button
      class="mb-5 ml-5 lg:w-1/8"
      :color="mainTabName == 'profile' ? 'primary' : '#0084FF'"
      :to="{
        name: 'Hospital-add/Edit',
        params: { ID: id },
      }"
      >{{ $t("Profile") }}</vs-button
    >

    <vs-button
      class="mb-5 ml-5 lg:w-1/8"
      :color="mainTabName == 'HospitalSurgeries' ? 'primary' : '#0084FF'"
      :to="{
        name: 'HospitalSurgeries',
        params: { ID: id },
      }"
      >{{ $t("Hospital Surgeries") }}</vs-button
    >

    <vs-button
      class="mb-5 ml-5 lg:w-1/8"
      :color="mainTabName == 'HospitalDoctors' ? 'primary' : '#0084FF'"
      :to="{
        name: 'HospitalDoctors',
        params: { ID: id },
      }"
      >{{ $t("Hospital Doctors") }}</vs-button
    >

    <vs-button
      class="mb-5 ml-5 lg:w-1/8"
      :color="mainTabName == 'HospitalSpecialities' ? 'primary' : '#0084FF'"
      :to="{
        name: 'HospitalSpecialities',
        params: { ID: id },
      }"
      >{{ $t("Hospital Specialities") }}</vs-button
    >

    <vs-button
      class="mb-5 ml-5 lg:w-1/8"
      :color="mainTabName == 'PatientSurgeries' ? 'primary' : '#0084FF'"
      :to="{
        name: 'PatientResevationSurgeries',
        params: { ID: id },
      }"
      >{{ $t("Booked surgeries") }}</vs-button
    >
  </div>
</template>
<script>
import moduleHospital from "@/store/Hospital/moduleHospital.js";

export default {
  data() {
    return {
      id: 0,
      HospitalModel: {},
    };
  },
  props: {
    mainTabName: {
      type: String,
      required: true,
    },
  },
  methods: {},

  created() {
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (this.$route.params.ID > 0) {
      this.id = this.$route.params.ID;
      this.$store.dispatch("HospitalList/GetHospital", this.id).then((res) => {
        this.HospitalModel = res.data.Data;
        this.$store.commit("SET_SetTitle", "" + this.HospitalModel.NameEN);
      });
    }
  },
};
</script>
<style>
</style>
