var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vx-row ml-5"},[_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName == 'profile' ? 'primary' : '#0084FF',"to":{
      name: 'Hospital-add/Edit',
      params: { ID: _vm.id },
    }}},[_vm._v(_vm._s(_vm.$t("Profile")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName == 'HospitalSurgeries' ? 'primary' : '#0084FF',"to":{
      name: 'HospitalSurgeries',
      params: { ID: _vm.id },
    }}},[_vm._v(_vm._s(_vm.$t("Hospital Surgeries")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName == 'HospitalDoctors' ? 'primary' : '#0084FF',"to":{
      name: 'HospitalDoctors',
      params: { ID: _vm.id },
    }}},[_vm._v(_vm._s(_vm.$t("Hospital Doctors")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName == 'HospitalSpecialities' ? 'primary' : '#0084FF',"to":{
      name: 'HospitalSpecialities',
      params: { ID: _vm.id },
    }}},[_vm._v(_vm._s(_vm.$t("Hospital Specialities")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName == 'PatientSurgeries' ? 'primary' : '#0084FF',"to":{
      name: 'PatientResevationSurgeries',
      params: { ID: _vm.id },
    }}},[_vm._v(_vm._s(_vm.$t("Booked surgeries")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }